import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                </div>

                <div className="section-main-description">
                  <p>Dear Ella,</p>

                  <p>
                    Your spunky sense of humor and big heart are already
                    changing the world. This fund will give you a chance to help
                    your community however you want. We can't wait to watch you
                    grow up and see the many ways you will make the world better
                    each and every day!
                  </p>

                  <p>We love you,</p>

                  <p>Amy, Andy, and Grace Kingman</p>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
