import React from "react";
import "./social-component.scss";

class SocialComponent extends React.Component {
  render() {
    return (
      <div className="social-container">
        <div className="row">
          <div className="social-container-help">
            <p>HELP BY SHARING</p>
          </div>
        </div>

        <div className="row">
          <div className="social-container-icons">
            <center>
              <div className="social-container-icons st-custom-button">
                <div
                  data-network="facebook"
                  className="st-custom-button"
                  href="https://ella.worldchangingdreams.givinga.com"
                >
                  <img src={require("../images/social/facebook.png")} />
                </div>
                <div
                  data-network="twitter"
                  className="st-custom-button"
                  href="https://ella.worldchangingdreams.givinga.com"
                >
                  <img src={require("../images/social/twitter.png")} />
                </div>
                <div
                  data-network="linkedin"
                  className="st-custom-button"
                  href="https://ella.worldchangingdreams.givinga.com"
                >
                  <img src={require("../images/social/linkedin.png")} />
                </div>
                <div
                  data-network="email"
                  className="st-custom-button"
                  href="https://ella.worldchangingdreams.givinga.com"
                >
                  <img src={require("../images/social/email.png")} />
                </div>
              </div>
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialComponent;
